import { useState, FormEvent } from "react";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";
import Schedule from "../assets/icons/schedule.png";
import Profit from "../assets/icons/profit.png";
import Support from "../assets/icons/support.png";
import BecomeDriver from "../assets/img/become-driver-tb.jpg";
import DriverImg from "../assets/img/driver.webp";

const Driver = () => {
  const [cityName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  return (
    <div>
      <Helmet>
        <title>Transportbidder | Driver</title>
        <meta
          name="description"
          content="Transportbidder offers innovative and efficient travel solutions, connecting travelers with service providers seamlessly."
        />
        <meta
          name="keywords"
          content="Transportbidder, travel solutions, travel services"
        />
        <meta name="author" content="Transportbidder Team" />
      </Helmet>
      <div className="lg:flex justify-between items-center gap-8 lg:columns-2  px-10 mx-10 p-10">
        {/* Left Side Banner Start */}
        <div>
          <img src={BecomeDriver} alt="Transportbidder Banner" className="" />
        </div>
        {/* Left Side Banner End */}

        {/* Right Side Banner Start */}
        <div>
          <div className="py-6">
            <h1 className="lg:text-4xl text-2xl font-semibold text-center">
              Confirm your phone number
              <br /> and fill out the application
            </h1>
          </div>
          <div className="py-6">
            <form onSubmit={handleSubmit}>
              <label
                htmlFor="companyName"
                className="block text-gray-700 font-semibold mb-2"
              >
                Your city
              </label>
              <input
                type="text"
                id="companyName"
                className="w-full px-3 py-4 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                value={cityName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
              <div className="my-4 ">
                <label
                  htmlFor="phone"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Your Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  className="w-full px-3 py-4 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 "
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <button
                type="submit"
                className="w-[200px] bg-blue-500 text-white text-align-center font-semibold py-5 lg:px-12 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              >
                Get Code
              </button>
              <div className="lg:w-[500px] mt-8 text-center lg:text-left m-6  mx-auto">
                <h3 className="font-bold  ">
                  Could not connect to the reCAPTCHA service. Please check your
                  internet connection and reload to get a reCAPTCHA challenge.
                </h3>
                <p className="mt-5">
                  By sending your request you accept our{" "}
                  <a href="/privacy" className="text-[#0030fc]">
                    Privacy Policy
                  </a>
                  , agree to receive marketing communications from inDrive via
                  SMS and calls, and agree to chat with us via WhatsApp
                </p>
              </div>
            </form>
          </div>
        </div>
        {/* Right Side Banner End */}
      </div>
      {/* How to become Driver */}
      <div className="text-center mx-10 ">
        <h1 className="lg:text-4xl text-2xl font-semibold text-center  lg:mt-20 mt-20 ">
          Become Driver
        </h1>
      </div>
      <div className="flex justify-center lg:my-10 my-4 lg:gap-8 ">
        <div>
          <img
            src={DriverImg}
            alt="Logo"
            className="mx-auto lg:h-[600px] h-[350px] items-center"
          />
        </div>
        <div>
          <div className="flex flex-col lg:items-start items-center">
            <div className=" bg-slate-200 lg:p-5 p-3 rounded-full">
              <img src={Profit} alt="Logo" />
            </div>
            <div>
              <h1 className="lg:text-2xl text-xl font-semibold m-2">
                Profitable Rides
              </h1>
            </div>
            <div>
              <p className="lg:text-start text-center lg:w-[500px] ">
                Set your own price for trips if the passenger's offer doesn't
                suit you. Enjoy low service fees of just 9-10% of the fare.
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:items-start items-center mt-5">
            <div className=" bg-slate-200 lg:p-5 p-3 rounded-full">
              <img src={Schedule} alt="Logo" />
            </div>
            <div>
              <h1 className="lg:text-2xl text-xl font-semibold m-2">
                Flexible Scheduling
              </h1>
            </div>
            <div>
              <p className="lg:text-start text-center lg:w-[500px] ">
                View the route before accepting an order. Skip as many ride
                requests as you want without any penalties.
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:items-start items-center mt-5">
            <div className=" bg-slate-200 lg:p-5 p-3 rounded-full">
              <img src={Support} alt="Logo" />
            </div>
            <div>
              <h1 className="lg:text-2xl text-xl font-semibold m-2">
                Always Here for You
              </h1>
            </div>
            <div>
              <p className="lg:text-start text-center lg:w-[500px] ">
                Access 24/7 in-app support, safety tools, and emergency calls
                during your rides. Your safety and satisfaction are our
                priorities.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Faq />
    </div>
  );
};
export default Driver;
