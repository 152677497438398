import React from "react";
import StarBig from "../assets/icons/star-big.svg";

const Banner: React.FC = () => {
  return (
    <div className="lg:w-[1200px] join join-vertical lg:ms-40 ms-10 lg:my-40 my-10 overflow-x-auto">
      <h1 className="flex flex-row text-4xl font-semibold justify-center text-[#002BE4] my-10">
        <img src={StarBig} alt="" className="h-6 m-2" />
        FAQS:
      </h1>

      <div className="collapse collapse-arrow join-item border border-base-300">
        <input type="radio" name="my-accordion-4" defaultChecked />
        <div className="collapse-title text-xl font-medium">
          How many passengers are allowed in the cabs of Transport Bidder?
        </div>
        <div className="collapse-content">
          <p>
            Usually, 4 passengers are allowed to travel in the compact cabs of
            Transport Bidder. However, if you want a cab that accommodates more
            than 4 people, you can book the same at an extra price as compared
            to the regular-sized cabs. We also offer rides in more seater SUVs
            on demand from passengers. Also, we carry pets, parcels, or bulky
            luggage of customers from one destination to another at a price on
            their request.{" "}
          </p>
        </div>
      </div>
      <div className="collapse collapse-arrow join-item border border-base-300">
        <input type="radio" name="my-accordion-4" />
        <div className="collapse-title text-xl font-medium">
          Who pays the toll tax while traveling in the cab of the Transport
          Bidder?
        </div>
        <div className="collapse-content">
          <p>
            Transport Bidder charges a fair price to the customers for enjoying
            the rides. This rate is fixed based on the distance between the
            pick-up and drop-off location of the passengers. The fare doesn't
            include the charge of the toll tax. The passenger has to pay it
            separately whenever applicable.{" "}
          </p>
        </div>
      </div>
      <div className="collapse collapse-arrow join-item border border-base-300">
        <input type="radio" name="my-accordion-4" />
        <div className="collapse-title text-xl font-medium">
          How to change the language settings of the app?
        </div>
        <div className="collapse-content">
          <p>
            Transport Bidder automatically sets the app language by detecting
            your device settings for language. So, to change the language of our
            app, you need to set the language of your phone according to your
            convenience.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};
export default Banner;
