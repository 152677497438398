import React from "react";
import MobileHome from "../assets/img/mobile-home-screen.webp";
import Car03 from "../assets/icons/car-03.svg";
import Plan from "../assets/icons/plan.png";
import SheildCheck from "../assets/icons/shield-check.svg";
import KeyFeaturesMultiple from "../assets/img/key-features.png";

const Work: React.FC = () => {
  return (
    <div>
      <div>
        <div className="py-8">
          <h1 className="lg:text-4xl text-2xl font-semibold text-center lg:mt-48 mt-5 lg:px-10 px-8 ">
            How Transportbidder Work
          </h1>
        </div>
        {/* How Transportbidder Work*/}
        <div className="lg:flex justify-between items-center gap-8 lg:columns-4  m-5 lg:mx-32 mx-12">
          <div className="lg:flex lg:flex-col columns-2">
            <div className=" text-white lg:flex flex-row-reverse ">
              <button className="text-3xl font-bold px-4 py-4 bg-[#002BE4] rounded-md">
                01
              </button>
            </div>
            <div>
              <h1 className="lg:text-2xl text-lg font-semibold lg:text-right text-left lg:my-5 my-2">
                Submit Quote
              </h1>
            </div>
            <div>
              <p className="lg:text-right lg:w-[400px] ">
                Share your trip details and desired price. TransportBidder
                matches you with suitable offers from verified providers.
              </p>
            </div>
            <div className=" text-white lg:flex flex-row-reverse mt-10 ">
              <button className="text-3xl font-bold px-4 py-4 bg-[#002BE4] rounded-md">
                02
              </button>
            </div>

            <div>
              <h1 className="lg:text-2xl text-lg font-semibold lg:text-right lg:my-5 my-2">
                Choose Offer
              </h1>
            </div>
            <div>
              <p className="lg:text-right lg:w-[400px]">
                Select the best offer from a range of options based on price,
                provider rating, and vehicle type.
              </p>
            </div>
          </div>
          <div className="my-5 ">
            <img src={MobileHome} alt="tb-app" className="lg:w-96 mx-5" />
          </div>
          <div className="lg:flex lg:flex-col columns-2">
            <div className=" text-white flex flex-row-start  ">
              <button className="text-3xl font-bold px-4 py-4 bg-[#002BE4] rounded-md">
                03
              </button>
            </div>
            <div>
              <h1 className="lg:text-2xl text-lg font-semibold lg:my-5 my-2">
                Safe Ride
              </h1>
            </div>
            <div>
              <p className="text-left lg:w-[400px]">
                Commence your journey with confidence, assured of a safe and
                reliable ride with our trusted providers.
              </p>
            </div>
            <div className=" text-white flex flex-row-start mt-10 ">
              <button className="text-3xl font-bold px-4 py-4 bg-[#002BE4] rounded-md">
                04
              </button>
            </div>
            <div>
              <h1 className="lg:text-2xl text-lg font-semibold lg:my-5 my-2">
                Arrive & Review
              </h1>
            </div>
            <div>
              <p className="text-left lg:w-[400px]">
                Upon reaching your destination, provide feedback on your
                experience to help maintain service quality.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Key Features of Transport App start */}
      <div className="lg:h-[600px] bg-[#002BE4] rounded-md py-12 lg:p-10">
        <div>
          <div className="lg:flex flex-row justify-between lg:mx-48 mx-10 lg:mt-10 mt-5">
            <h1 className="lg:text-4xl text-2xl text-[#fdfdff] lg:w-[500px]">
              Key features of <br />
              Transportbidder App
            </h1>
            <div className="border-l-4 h-14 my-5 lg:ms-5">
              <p className=" border-[#eae737] ms-5 text-white">
                Versatile delivery options include car, truck, ambulance,
                motorcycle, bicycle, and foot services. Multiple delivery
                services to suit all your needs, ensuring flexibility and
                efficiency.
              </p>
            </div>
          </div>
        </div>
        <div className="lg:flex flex-col items-center">
          <img
            src={KeyFeaturesMultiple}
            alt="Tb mobile app"
            className=" mt-20 lg:mt-0"
          />
        </div>
      </div>
      {/* Key Features of Transport App end */}

      {/* the app fair rides start */}
      <div className="lg:flex flex-col lg:mt-96 mt-20">
        <div className="text-center">
          <h1 className="lg:text-4xl text-2xl font-semibold ">
            Effortless Transport Solutions
          </h1>
        </div>
        <div className="lg:flex justify-between items-center gap-8 lg:columns-4 lg:m-20 m-5 lg:mx-32 mx-12 ">
          <div className="flex flex-col items-center justify-center ">
            <div className=" bg-slate-200 p-5 rounded-full">
              <img src={Plan} alt="Plan" className="lg:w-12" />
            </div>
            <div>
              <h1 className="lg:text-2xl text-lg font-semibold m-2">
                Streamlined Booking
              </h1>
            </div>
            <div>
              <p className="text-center lg:w-[400px]">
                Simplify your travel plans with our intuitive booking system,
                ensuring hassle-free reservations at your fingertips.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center mt-10 lg:mt-0">
            <div className=" bg-slate-200 p-5 rounded-full">
              <img src={Car03} alt="Logo" />
            </div>
            <div>
              <h1 className="lg:text-2xl text-lg font-semibold m-2">
                Diverse Fleet Selection
              </h1>
            </div>
            <div>
              <p className="text-center lg:w-[400px]">
                Choose from a wide array of vehicles, tailored to your specific
                needs and preferences, for a comfortable and personalized
                journey.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center mt-10 lg:mt-0">
            <div className=" bg-slate-200 p-5 rounded-full">
              <img src={SheildCheck} alt="safety" />
            </div>
            <div>
              <h1 className="lg:text-2xl text-lg font-semibold m-2">
                Seamless Transactions
              </h1>
            </div>
            <div>
              <p className="text-center lg:w-[400px]">
                Enjoy secure and seamless payment processing, guaranteeing peace
                of mind throughout your travel experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* the app fair rides end */}
    </div>
  );
};
export default Work;
