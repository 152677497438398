import React from "react";
import StarBigWhite from "../assets/icons/star-big-white.svg";
import GooglePlay from "../assets/icons/google-play.svg";
import AppStore from "../assets/icons/app-store.svg";
import DownloadApp from "../assets/img/download-app.webp";

const DownloadNow: React.FC = () => {
  return (
    <div>
      <div className="lg:flex lg:columns-2 gap-24 justify-center items-center bg-[#002BE4] mx-auto">
        {/* Left Side Banner Start */}
        <div className="lg:text-left">
          <h1 className="flex flex-row justify-center lg:text-4xl text-2xl font-semibold text-white lg:my-10 my-4 pt-5">
            <img src={StarBigWhite} alt="" className="h-6 m-2 text-white" />
            Download Transportbidder App Now
          </h1>
          <div className="lg:py-2 lg:w-[500px] text-white lg:text-left text-center mx-auto justify-center lg:ms-10">
            <p>
              Experience the ultimate convenience in transportation and delivery
              with our app. Book cabs, ambulances, bikes, and more, all from
              your smartphone. Enjoy safe, fast, and reliable service tailored
              to your needs. Download today and simplify your travel!
            </p>
          </div>
          <div className="flex flex-row gap-2 justify-center text-black lg:py-2 mx-auto  ">
            <button className="py-2 rounded">
              <img
                src={GooglePlay}
                alt="download tb"
                className="h-[100px] lg:w-[180px]"
              />
            </button>
            <button className="px-4 py-2 rounded">
              <img
                src={AppStore}
                alt="download tb"
                className="h-[100px] lg:w-[180px]"
              />
            </button>
          </div>
        </div>
        {/* Left Side Banner End */}

        {/* Right Side Banner Start */}
        <div>
          <img
            src={DownloadApp}
            alt="Transportbidder Banner"
            className="lg:h-[600px] h-[400px] mx-auto "
          />
        </div>
      </div>
    </div>
  );
};
export default DownloadNow;
