import { useState, FormEvent } from "react";
import { Helmet } from "react-helmet";
import StarBig from "../assets/icons/star-big.svg"
import RegistrationIcon from "../assets/img/tb-registration.webp"

const RegisterB2BPage = () => {
  const [companyName, setCompanyName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <div>
        <Helmet>
          <title>Register as B2B | Transportbidder</title>
          <meta
            name="description"
            content="Transportbidder offers innovative and efficient travel solutions, connecting travelers with service providers seamlessly."
          />
          <meta
            name="keywords"
            content="Transportbidder, travel solutions, travel services"
          />
          <meta name="author" content="Transportbidder Team" />
        </Helmet>
        <h1 className="flex flex-row lg:text-4xl text-2xl font-semibold justify-center text-[#002BE4] lg:mt-24 mt-16">
          <img src={StarBig} alt="" className="h-6 m-2" />
          Register as B2B
        </h1>
      </div>
      <div className="gap-8 md:grids-cols-2 lg:flex justify-between lg:px-10 px-2 lg:mx-10 mx-2 lg:my-12 p-2 overflow-x-auto mx:auto">
        {/* Left Side Banner Start */}
        <div>
          <img
            src={RegistrationIcon}
            alt="Regisration as B2B"
            className="w-[500px]"
          />
        </div>
        {/* Left Side Banner End */}

        <div className="lg:w-[600px] mx-auto p-6 bg-white rounded-lg">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="companyName"
                className="block text-gray-700 font-semibold mb-2"
              >
                Company Name
              </label>
              <input
                type="text"
                id="companyName"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="contactPerson"
                className="block text-gray-700 font-semibold mb-2"
              >
                Contact Person
              </label>
              <input
                type="text"
                id="contactPerson"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                value={contactPerson}
                onChange={(e) => setContactPerson(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 font-semibold mb-2"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="phone"
                className="block text-gray-700 font-semibold mb-2"
              >
                Phone
              </label>
              <input
                type="tel"
                id="phone"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-gray-700 font-semibold mb-2"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="confirmPassword"
                className="block text-gray-700 font-semibold mb-2"
              >
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="vehicleType"
                className="block text-gray-700 font-semibold mb-2"
              >
                Vehicle Type
              </label>
              <input
                type="text"
                id="vehicleType"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                value={vehicleType}
                onChange={(e) => setVehicleType(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="vehicleNumber"
                className="block text-gray-700 font-semibold mb-2"
              >
                Vehicle Number
              </label>
              <input
                type="text"
                id="vehicleNumber"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                value={vehicleNumber}
                onChange={(e) => setVehicleNumber(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Register
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default RegisterB2BPage;
