import React from "react";

interface PrivacyPolicyContentProps {
  content: string;
}

const PrivacyPolicyContent: React.FC<PrivacyPolicyContentProps> = ({ content }) => {
  return (
    <div
      className="privacy-policy-content"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default PrivacyPolicyContent;
