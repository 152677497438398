import React from "react";
import Money from "../assets/icons/money.svg";
import ShieldCheck from "../assets/icons/shield-check.svg";
import Car03 from "../assets/icons/car-03.svg";
import DeliveryDelay from "../assets/icons/delivery-delay-01.svg";

const Services: React.FC = () => {
  return (
    <div>
      <div className="text-center">
        <div>
          <h1 className="lg:text-4xl text-2xl font-semibold text-center lg:mt-48 mt-14 mx-10 ">
            Why Choose Us?
          </h1>
        </div>
        <div className="lg:flex justify-between items-center gap-8 lg:columns-4 columns-2 lg:m-20 m-10 lg:mx-32 mx-10">
          <div className="flex flex-col items-center justify-center  ">
            <div className=" bg-slate-200 p-5 rounded-full">
              <img src={Money} alt="Logo" />
            </div>
            <div>
              <h3 className="lg:text-2xl text-xl font-semibold m-2">
                Know Your Ride Cost
              </h3>
            </div>
            <div>
              <p className="lg:text-center">
                Get accurate fare estimates before you book. No surprises, just
                straightforward pricing for every trip. Plan your budget and
                travel with confidence.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center mt-10 lg:mt-0">
            <div className=" bg-slate-200 p-5 rounded-full">
              <img src={ShieldCheck} alt="Logo" />
            </div>
            <div>
              <h3 className="text-2xl font-semibold m-2">Safe Transactions</h3>
            </div>
            <div>
              <p className="text-center">
                Enjoy peace of mind with our secure payment system. Your
                financial information is protected. Pay effortlessly and safely
                through our app.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className=" bg-slate-200 p-5 rounded-full">
              <img src={Car03} alt="Logo" />
            </div>
            <div>
              <h3 className="text-2xl font-semibold m-2">Quick and Simple</h3>
            </div>
            <div>
              <p className="text-center">
                Effortlessly book your rides with our user-friendly app. Just a
                few taps and you're set. Experience quick and easy bookings
                anytime, anywhere.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center mt-10 lg:mt-0">
            <div className=" bg-slate-200 p-5 rounded-full">
              <img src={DeliveryDelay} alt="Logo" />
            </div>
            <div>
              <h3 className="text-2xl font-semibold m-2">Reliable Delivery</h3>
            </div>
            <div>
              <p className="text-center ">
                Send packages quickly and securely with our app. Enjoy
                hassle-free service with real-time tracking to ensure your
                packages arrive safely.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
