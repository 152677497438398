import React, { useState } from "react";
import Car03 from "../assets/icons/car-03.svg";
import Safety from "../assets/icons/safety.png";
import Offer from "../assets/icons/offer.png";
import Schedule from "../assets/icons/schedule.png";
import Profit from "../assets/icons/profit.png";
import Support from "../assets/icons/support.png";
import DriverPassenger from "../assets/img/driver-passanger.webp";
import Driver from "../assets/img/driver.webp";

const Toggle: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string | null>(
    "Passenger"
  );
  const [passengerSelectedOnce, setPassengerSelectedOnce] =
    useState<boolean>(false);

  const handleOptionClick = (option: string) => {
    setSelectedOption((prevOption) => (prevOption === option ? null : option));
  };

  const handleDriverClick = () => {
    setSelectedOption("Driver");
    if (!passengerSelectedOnce) {
      setPassengerSelectedOnce(true); // Set passengerSelectedOnce to true after selecting Passenger once
    }
  };

  return (
    <div>
      <div>
        <div className="text-center mx-10 ">
          <h1 className="lg:text-4xl text-2xl font-semibold text-center  lg:mt-20 mt-20 ">
            Choose Your Role
          </h1>
          <p className="lg:mt-10 mt-4 lg:w-[800px] text-center lg:mx-auto">
            Whether you're a passenger looking for a quick, affordable ride or a
            driver seeking profitable opportunities, our app is designed to meet
            your needs. Passengers can enjoy competitive fares and reliable
            service, while drivers benefit from flexible hours and fair
            earnings. Join our community and experience seamless, efficient
            transportation at your fingertips. We've got you covered, no matter
            your role.
          </p>
        </div>
        <div className="flex justify-center lg:my-10 my-4 display:block ">
          <div className="base-400">
            <div
              tabIndex={0}
              role="button"
              className={`btn m-1 ${
                selectedOption === "Passenger" ? "btn-active btn-primary" : ""
              }`}
              onClick={() => handleOptionClick("Passenger")}
            >
              Passenger
            </div>
            <div
              tabIndex={0}
              role="button"
              className={`btn m-1 ${
                selectedOption === "Driver" ? "btn-active btn-primary" : ""
              }`}
              onClick={handleDriverClick}
            >
              Driver
            </div>
          </div>
        </div>

        {selectedOption && (
          <div className="lg:flex flex-row mt-4 mx-10 gap-10 ">
            <img
              src={selectedOption === "Passenger" ? DriverPassenger : Driver}
              alt=""
              className="mx-auto lg:h-[600px] h-[350px] items-center"
            />
            {selectedOption === "Passenger" && (
              <div>
                <div className="flex flex-col lg:items-start items-center">
                  <div className=" bg-slate-200 lg:p-5 p-3 rounded-full">
                    <img src={Offer} alt="Passenger Offer" />
                  </div>
                  <div>
                    <h1 className="lg:text-2xl text-xl font-semibold m-2">
                      Write Offers
                    </h1>
                  </div>
                  <div>
                    <p className="lg:text-start text-center lg:w-[500px] ">
                      Write your quote with price and trip details for your
                      ride. Enjoy flexibility and control over your travel
                      costs.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:items-start items-center mt-5">
                  <div className=" bg-slate-200 lg:p-5 p-3 rounded-full">
                    <img src={Car03} alt="Safety" className="w-12" />
                  </div>
                  <div>
                    <h1 className="lg:text-2xl text-xl font-semibold m-2">
                      Get Best Deals
                    </h1>
                  </div>
                  <div>
                    <p className="lg:text-start text-center lg:w-[500px] ">
                      Select the best price offer for your trip from multiple
                      options. Compare quotes to ensure you get the best deal.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:items-start items-center mt-5">
                  <div className=" bg-slate-200 lg:p-5 p-3 rounded-full">
                    <img src={Safety} alt="Safety" />
                  </div>
                  <div>
                    <h1 className="lg:text-2xl text-xl font-semibold m-2">
                      Safe Ride
                    </h1>
                  </div>
                  <div className="lg:mb-14">
                    <p className="lg:text-start text-center lg:w-[500px] ">
                      Start your safe trip and reach your destination
                      comfortably. Our reliable drivers ensure a secure and
                      pleasant journey.
                    </p>
                  </div>
                </div>
              </div>
            )}
            {selectedOption === "Driver" && (
              <div className="mb-10">
                <div className="flex flex-col lg:items-start items-center">
                  <div className=" bg-slate-200 lg:p-5 p-3 rounded-full">
                    <img src={Profit} alt="Logo" />
                  </div>
                  <div>
                    <h1 className="lg:text-2xl text-xl font-semibold m-2">
                      Profitable Rides
                    </h1>
                  </div>
                  <div>
                    <p className="lg:text-start text-center lg:w-[500px] ">
                      Set your own price for trips if the passenger's offer
                      doesn't suit you. Enjoy low service fees of just 9-10% of
                      the fare.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:items-start items-center mt-5">
                  <div className=" bg-slate-200 lg:p-5 p-3 rounded-full">
                    <img src={Schedule} alt="Logo" />
                  </div>
                  <div>
                    <h1 className="lg:text-2xl text-xl font-semibold m-2">
                      Flexible Scheduling
                    </h1>
                  </div>
                  <div>
                    <p className="lg:text-start text-center lg:w-[500px] ">
                      View the route before accepting an order. Skip as many
                      ride requests as you want without any penalties.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:items-start items-center mt-5">
                  <div className=" bg-slate-200 lg:p-5 p-3 rounded-full">
                    <img src={Support} alt="Logo" />
                  </div>
                  <div>
                    <h1 className="lg:text-2xl text-xl font-semibold m-2">
                      Always Here for You
                    </h1>
                  </div>
                  <div>
                    <p className="lg:text-start text-center lg:w-[500px] ">
                      Access 24/7 in-app support, safety tools, and emergency
                      calls during your rides. Your safety and satisfaction are
                      our priorities.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Toggle;
