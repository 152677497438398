import React from "react";
import Banner from "../components/Banner";
import Services from "../components/Services";
import Work from "../components/Work";
import Toggle from "../components/Toggle";
import DownloadNow from "../components/DownloadNow";
import HowStart from "../components/HowStart";
import { Helmet } from "react-helmet";
// import Contact from "../components/Contact"

const Home: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Transportbidder - Innovative Travel Solutions</title>
        <meta
          name="description"
          content="Transportbidder offers innovative and efficient travel solutions, connecting travelers with service providers seamlessly."
        />
        <meta
          name="keywords"
          content="Transportbidder, travel solutions, travel services"
        />
        <meta name="author" content="Transportbidder Team" />
      </Helmet>
      <div id="home">
        <Banner />
        <Services />
        <Work />
        <Toggle />
        {/* <HowStart /> */}
        {/* <Contact /> */}
        <DownloadNow />
      </div>
    </>
  );
};
export default Home;
