import { useState, FormEvent } from "react";
import { Helmet } from "react-helmet";
import ContactImage from "../assets/img/tb-contact.webp"
import StarBig from "../assets/icons/star-big.svg"

const Contact = () => {
  const [subject, setSubject] = useState("");
  const [country, setCountry] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <Helmet>
        <title>Contact | Transportbidder </title>
        <meta
          name="description"
          content="Transportbidder offers innovative and efficient travel solutions, connecting travelers with service providers seamlessly."
        />
        <meta
          name="keywords"
          content="Transportbidder, travel solutions, travel services"
        />
        <meta name="author" content="Transportbidder Team" />
      </Helmet>

      <div id="contact" className="bg-[#F2F5FF] lg:py-24 p-10">
        <h1 className="flex flex-row text-4xl font-semibold justify-center text-[#002BE4]">
          <img src={StarBig} alt="" className="h-6 m-2" />
          Contact Us
        </h1>
        <div className="gap-8 md:grids-cols-2 lg:flex justify-between items-center lg:px-10 px-2 lg:mx-10 mx-2 lg:my-12 p-2 overflow-x-auto mx:auto ">
          {/* Left Side Banner Start */}
          <div>
            <img
              src={ContactImage}
              alt="Transportbidder Contact"
              className="w-[600px]"
            />
          </div>
          {/* Left Side Banner End */}

          {/* Rigth Side Banner Start */}
          <div className="lg:w-[500px]">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="companyName"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="companyName"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="contactPerson"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Select Country
                </label>
                <input
                  type="text"
                  id="contactPerson"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Name
                </label>
                <input
                  type="name"
                  id="name"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="phone"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Phone
                </label>
                <input
                  type="tel"
                  id="phone"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="message"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Message
                </label>
                <input
                  type="text"
                  id="message"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>

              <button
                type="submit"
                className="w-full h-14 bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              >
                Submit
              </button>
            </form>
          </div>
          {/* Right Side Banner End */}
        </div>
      </div>
    </>
  );
};

export default Contact;
