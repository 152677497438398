import React from "react";
import { Helmet } from "react-helmet";
import Faq from "../components/Faq";
import Testimonilas from "../components/TestimonialList";
import PrivacyPolicy from "../assets/img/privacy-policy-tb.webp";
import Mission from "../assets/img/mission.webp";
import Vision from "../assets/img/vision.webp";

const About: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>About us | Transportbidder</title>
        <meta
          name="description"
          content="Transportbidder offers innovative and efficient travel solutions, connecting travelers with service providers seamlessly."
        />
        <meta
          name="keywords"
          content="Transportbidder, travel solutions, travel services"
        />
        <meta name="author" content="Transportbidder Team" />
      </Helmet>

      <div>
        <img
          src={PrivacyPolicy}
          className="lg:w-full lg:h-[350px] h-[250px] object-cover"
          alt="Transportbidder Privacy Policy"
        />
        <div className="container mx-auto py-10 px-4 lg:px-20">
          {/* About Us Section */}
          <section className="lg:py-24 lg:mx-10 py-8 mx-4">
            <h1 className="text-3xl font-bold text-gray-800 py-4">ABOUT US</h1>
            <p className=" text-gray-500 leading-loose ">
              Transportbidder is a company born from the collaborative spirit of
              entrepreneurs hailing from Peru, India, and the USA. Our team
              boasts a rich diversity of backgrounds and expertise, including a
              US army war veteran, a mechanical engineer with a specialization
              in global transportation, and a dedicated healthcare professional
              committed to preserving human life.
              <br />
              <br />
              Recognizing the myriad challenges inherent in global
              transportation, we are driven by core values such as honesty,
              ethics, and unwavering commitment to human service. Our aim is not
              merely to navigate logistical complexities but to redefine the
              industry through integrity and compassion, ensuring that every
              journey we facilitate is marked by efficiency, safety, and respect
              for human dignity.
            </p>
          </section>

          {/* Objectives Section */}
          <section className=" bg-[#002be4] text-white px-6 py-6 sm:py-32 lg:px-12">
            <h2 className="text-3xl font-bold text-gray-100 py-4 text-center ">
              OBJECTIVES
            </h2>
            <p className="text-gray-100 text-center leading-loose lg:mx-8">
              At Transportbidder, our mission is clear: to deliver a global
              transportation service that sets the standard for comfort, safety,
              speed, and quality, all while providing personalized and attentive
              care. Beyond merely moving people and goods from point A to point
              B, we strive to create experiences that exceed expectations and
              leave a lasting positive impression.
              <br />
              <br />
              Central to our ethos is the commitment to fairness, ensuring that
              our rates are equitable for both our valued drivers and discerning
              customers alike. Our ultimate goal is to foster satisfaction among
              all stakeholders, fostering long-term relationships built on
              trust, reliability, and mutual respect.
            </p>
          </section>

          <div className="lg:flex lg:flex-row flex flex-col justify-between gap-10 mx-auto lg:py-20 ">
            <section>
              <div>
                <img
                  src={Mission}
                  alt="Transportbidder Banner"
                  className="w-[650px]"
                />
              </div>
            </section>
            {/* text */}
            <section className="lg:w-[600px] mb-10 mx-5">
              <h3 className="lg:text-3xl text-xl  font-bold text-gray-800 py-4 lg:text-left text-center mt-5 lg:mt-0 ">
                MISSION
              </h3>
              <p className="text-gray-600 leading-loose text-center lg:text-left">
                Our mission at Transportbidder is to revolutionize the
                transportation industry by providing exceptional service that
                combines efficiency, safety, and human touch.
                <br />
                <br />
                We aim to be the preferred choice for customers seeking reliable
                and compassionate transportation solutions, ensuring that each
                journey contributes to a better, more connected world.
              </p>
            </section>
            {/* Img */}
          </div>

          {/* Vission */}
          <div className="lg:flex lg:flex-row flex flex-col gap-4 mx-auto lg:py-10 ">
            <section className="">
              <div>
                <img
                  src={Vision}
                  alt="Transportbidder Banner"
                  className=" w-[700px] "
                />
              </div>
            </section>

            {/* text */}
            <section className="lg:w-[600px] mb-10 mx-5">
              <h4 className="lg:text-3xl text-xl font-bold text-gray-800 text-center py-4 mt-5 lg:mt-0">
                VISION
              </h4>
              <p className=" text-gray-600 leading-loose text-center lg:text-left">
                At Transportbidder, our vision stretches beyond the horizon of
                mere transportation logistics. We envision a world seamlessly
                connected through our services, where geographical boundaries
                are blurred, and opportunities are accessible to all.
                <br />
                <br />
                Our aim is to become the epitome of excellence in global
                transportation, setting new standards in comfort, safety, and
                efficiency. With a commitment to innovation and sustainability,
                we aspire to shape the future of mobility, embracing emerging
                technologies and eco-friendly practices. Our vision extends to
                creating a network of empowered drivers and satisfied customers,
                united by a shared sense of trust and reliability. We see
                ourselves not just as a transportation provider but as a
                catalyst for positive change, enriching lives and driving
                progress in every community we serve.
              </p>
            </section>
          </div>
        </div>
        {/* <Testimonilas /> */}
        <Faq />
      </div>
    </>
  );
};

export default About;
