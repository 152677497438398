import Header from "./components/Header";
import Home from "./pages/Home";
import Driver from "./pages/Driver";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Registerb2b from "./pages/Registerb2b";
import Work from "./components/Work";
import Contact from "./components/Contact";
import Privacy from "./pages/Privacy";
import TransitionWrapper from "./components/TransitionWrapper";
import About from "./pages/About"

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Header />
        <TransitionWrapper>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/work" element={<Work />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/registerb2b" element={<Registerb2b />} />
            <Route path="/driver" element={<Driver />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </TransitionWrapper>
        <Footer />
      </div>
    </Router>
  );
};
export default App;
